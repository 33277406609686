import { mdiInformationOutline, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import "./ExtendSubmitDate.scss";
import OTPInput from "react-otp-input";
import { useEffect, useState } from "react";
import { useTimer } from "../../Hooks/useTimer";
import { useAuth } from "../Context/AuthContext";

interface ExtendSubmitDateProps {
  toggleExtendSubmitDateModal: () => void;
  onHandleExtend: () => void;
  newSubmitDate: string;
  setNewSubmitDate: React.Dispatch<React.SetStateAction<string>>;
  minPublishingDate: string;
  extendLoading: boolean;
  otp?: string;
  setOtp?: React.Dispatch<React.SetStateAction<string>>;
  onResendOTP: any;
  tender_id?: string | number | undefined;
}

export const ExtendSubmitDate: React.FC<ExtendSubmitDateProps> = ({
  extendLoading,
  toggleExtendSubmitDateModal,
  onHandleExtend,
  newSubmitDate,
  setNewSubmitDate,
  minPublishingDate,
  otp,
  setOtp,
  onResendOTP,
  tender_id,
}) => {
  const { userData } = useAuth();
  const RESEND_INTERVAL_S = 0;
  const [disableResendOtp, setDisableResentOtp] = useState(true);
  const { time, reStartTimer } = useTimer();

  useEffect(() => {
    if (disableResendOtp) {
      reStartTimer();
    }
  }, [disableResendOtp]);

  useEffect(() => {
    if (time === RESEND_INTERVAL_S) {
      setDisableResentOtp(false);
    }
  }, [time]);
  return (
    <div className="confirmation-popup">
      <div className="close-icon" onClick={toggleExtendSubmitDateModal}>
        <Icon className=" mdi-close" path={mdiClose} />
      </div>
      <div className="info-logo">
        <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
      </div>
      <div>
        <h5 className="text-title">Are you sure you want to extend date?</h5>
      </div>
      <div className="popup-body">
        <div className="alert-section">
          <div>
            <Icon className="mdi-alert" path={mdiInformationOutline}></Icon>
          </div>
          <div>
            <p className="withdraw-bid">
              New Bidders can make bids if the submission deadline is extended.
            </p>
          </div>
        </div>
        <div className="extend-date">
          <label>Select New Submission Date</label>
          <input
            className="form-control"
            min={minPublishingDate}
            type="date"
            value={newSubmitDate}
            onChange={(e) => setNewSubmitDate(e.target.value)}
          />
        </div>
        <hr />
        <div className="d-flex flex-column justify-content-center">
          <p className="mb-2">
            <b>
              OTP has been sent to {userData?.company_contact_no}. PLEASE ENTER
              OTP:
            </b>
          </p>
          <OTPInput
            inputStyle="form-control otp-input-custom mb-2"
            inputType="tel"
            value={otp}
            onChange={(value) => (setOtp ? setOtp(value) : {})}
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;</span>}
            renderInput={(props) => <input {...props} />}
          />
          {disableResendOtp ? (
            <span className="text-secondary">
              You can resend otp after {time} sec
            </span>
          ) : (
            <span
              role="button"
              className="text-secondary"
              onClick={() => {
                onResendOTP(tender_id);
                setDisableResentOtp(true);
              }}
            >
              <u>Resend OTP</u>
            </span>
          )}
        </div>
      </div>
      <div className="popup-btn">
        <button className="btn-cancel" onClick={toggleExtendSubmitDateModal}>
          Cancel
        </button>
        <button
          className="btn-withdraw"
          onClick={() => {
            onHandleExtend();
          }}
          disabled={extendLoading}
        >
          Extend
        </button>
      </div>
    </div>
  );
};
