import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";

interface CellRendererProps {
  cell: any;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "350px",
  },
};

export const CellRenderer: React.FC<CellRendererProps> = ({ cell }) => {
  const [isEllipsis, setIsEllipsis] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      //@ts-ignore
      const hasEllipsis = textRef.current.scrollWidth > textRef.current.clientWidth;
      setIsEllipsis(hasEllipsis);
    }
  }, [cell.row.values.remark]);

  const toggleOpenModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleOpenModal}
        contentLabel="My dialog"
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="d-flex justify-content-between confirmation-popup mb-3">
          <p className="text-title text-capitalize mb-0">Remarks for {cell.row.values.organization_name}</p>
          <div className="close-icon" onClick={toggleOpenModal}>
            <Icon className=" mdi-close" path={mdiClose} />
          </div>
        </div>
        <p className="small-content text-capitalize">{cell.row.values.remark}</p>
      </Modal>
      <div className="remark-text-section">
        <p className="remark-text" ref={textRef}>
          {cell.row.values.remark}
        </p>
        {isEllipsis && cell.row.values.remark !== "" && cell.row.values.remark !== null && (
          <p className="link-font" onClick={toggleOpenModal}>
            More
          </p>
        )}
      </div>
    </>
  );
};
